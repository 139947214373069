<template>
  <!-- <nav class="navbar navbar-expand-lg navbar-dark bg-black sticky-top">
		<div class="container-fluid">
			<router-link to="/" class="navbar-brand">G'Black</router-link>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarTogglerDemo03">
				<ul class="navbar-nav ms-auto mb-2 mb-lg-0">
					<li class="nav-item">
						<router-link to="/" class="nav-link active" aria-current="page">Home</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/resume" class="nav-link">Résumé</router-link>
					</li>
					<li class="nav-item">
						<a href="#testimonySection" class="nav-link">Testimony</a>
					</li>
					<li class="nav-item">
						<a href="#contact-section" class="nav-link">Contact Me</a>
					</li>
				</ul>
			</div>
		</div>
	</nav> -->

  <nav class="navbar navbar-dark bg-black sticky-top">
    <div class="container justify-content-between">
      <router-link to="/" class="navbar-brand cursorPointer">
        <Text text="<G.Black />" fontSize="22px" fontFamily="Bebas Neue" />
      </router-link>
      <ul class="navbar-nav flex-row justify-content-center mb-lg-0 gap-3">
        <!-- <li class="nav-item">
					<router-link to="/" class="nav-link active" aria-current="page">Home</router-link>
				</li> -->
        <!-- <li class="nav-item">
					<router-link to="/resume" class="nav-link">Résumé</router-link>
				</li> -->
        <li v-for="item in socialMediaHandles" :key="item" class="nav-item">
          <a
            @click="goToSocial(item)"
            :href="item.link"
            target="_blank"
            class="nav-link small text-nowrap"
          >
            <span class="me-2 primaryColor"><i :class="item.icon"></i></span>
            <Text :text="item.name" fontSize="15px" />
          </a>
        </li>
        <!-- <li class="nav-item ms-3">
          <a href="#contact-section" class="nav-link">Contact Me</a>
        </li> -->
        <!-- <li class="nav-item">
					<a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
				</li> -->
      </ul>
    </div>
  </nav>

  <!-- <nav
		:class="{ 'scrolled': !view.atTopOfPage }"
		class="fixed flex w-full bg-white border-b items-center justify-between flex-wrap p-5 m-auto top-0 animated">
	</nav> -->
</template>
<script>
import Text from "../../Text.vue";

export default {
  name: "Navbar",

  data() {
    return {
      view: {
        atTopOfPage: true,
      },
      socialMediaHandles: [
        {
          name: "LinkedIn",
          icon: "fa fa-linkedin",
          link: "https://www.linkedin.com/in/olawale-oladiran",
        },
        {
          name: "Github",
          icon: "fa fa-github",
          link: "https://github.com/Gblack-Hub",
        },
        // {
        //   name: 'WhatsApp',
        //   icon: 'fa fa-whatsapp',
        //   link: 'https://wa.me/2348039275558',
        // },
      ],
    };
  },
  components: {
    Text,
  },

  // // a beforeMount call to add a listener to the window
  // beforeMount () {
  // 	window.addEventListener('scroll', this.handleScroll);
  // },

  methods: {
    goToSocial(social) {
      this.$gtag.event("socials_opened", {
        event_label: social.name,
      });
    },
    // 	// the function to call when the user scrolls, added as a method
    // 	handleScroll(){
    // 		// when the user scrolls, check the pageYOffset
    // 		if(window.pageYOffset>0){
    // 			// user is scrolled
    // 			if(this.view.atTopOfPage) this.view.atTopOfPage = false
    // 		}else{
    // 			// user is at top of page
    // 			if(!this.view.atTopOfPage) this.view.atTopOfPage = true
    // 		}
    // 	}
  },
};
</script>
<style scoped>
.nav-item a {
  color: #2c3e50;
}

.nav-item a.router-link-exact-active,
.nav-item a.router-link-exact-active:active,
.nav-item a.nav-link.active {
  /* color: #42b983; */
  color: #ffffff;
}

/* nav {
		z-index: 10
	}

	nav.scrolled {
		@apply shadow-2xl;
		border-bottom: 0px;
	} */
</style>
