<template>
  <div class="card darkContainerBox text-white w-100">
    <img
      :src="`/images/${project.image}.webp`"
      class="card-img-top projectImg"
      @error="setFallbackImage"
      :alt="project.name"
      lazy
    />
    <div class="card-body">
      <a
        @click="goToProject(project)"
        :href="project.links.website"
        target="_blank"
        class="text-decoration-none card-title text-white lead mb-1"
        >{{ project.name }}
      </a>
      <!-- <p class="card-text small text-white-50">{{ project.detail }}</p> -->
      <!-- <div class="small">Technologies:</div> -->
      <div class="mt-3">
        <span
          class="small text-white-50"
          v-for="(tech, index) in project.technologies"
          :key="tech"
          >{{ tech }}{{ index + 1 === project.technologies.length ? "" : ", " }}
        </span>
      </div>
      <div class="mt-3 d-flex justify-content-between align-items-center small">
        <div>
          <a
            v-if="project.links.website != ''"
            @click="goToProject(project)"
            :href="project.links.website"
            target="_blank"
            class="btn btn-outline-light btn-sm px-3 rounded-pill text-decoration-none text-capitalize visitBtn"
          >
            visit
            <span><i class="fa fa-long-arrow-right pr-2 ms-2"></i></span>
          </a>
        </div>
        <!-- <div v-if="project.links.source != ''">
          <a
            :href="project.links.source"
            target="_blank"
            class="text-decoration-none text-white-50 text-capitalize"
          >
            <span><i class="fa fa-github"></i></span> source
          </a>
        </div>
        <div v-if="project.links.doc != ''">
          <a
            :href="project.links.doc"
            target="_blank"
            class="text-decoration-none text-white-50 text-capitalize"
          >
            <span><i class="fa fa-github"></i></span> Doc
          </a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Project",
  props: {
    project: Object,
  },
  data() {
    return {
      fallbackImage: "/images/g.jpeg", // The fallback image URL
    };
  },
  methods: {
    setFallbackImage(event) {
      // When image fails to load, set the fallback image
      event.target.src = this.fallbackImage;
    },
    goToProject(project) {
      this.$gtag.event("project_opened", {
        event_category: "Projects",
        event_label: project.id,
      });
    },
  },
};
</script>

<style scoped>
.projectImg {
  height: 190px;
  object-fit: cover;
}
.visitBtn {
  color: rgba(255, 255, 255, 0.5);
}
.visitBtn:hover {
  color: black;
}
</style>
