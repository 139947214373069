<template>
  <section class="darkContainerBox py-5">
    <div class="container py-3" id="contact-section">
      <div class="row mt-5">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-5 mb-md-0">
          <h2 class="sectionTitle text-white h2">Contact me</h2>
          <hr class="topicLine" />

          <p class="mt-5 mb-5 text">
            Got a question or interested in collaborating? Feel free to share
            your details, and I'll get back to you as soon as possible!
          </p>
          <div class="d-flex">
            <div
              v-for="item in socialMediaHandles"
              :key="item"
              class="d-flex me-3"
            >
              <a
                @click="goToSocial(item)"
                :href="item.link"
                target="_blank"
                class="text-decoration-underline text-white small"
              >
                <span class="fa-stack fa-lg me-2">
                  <i class="fa-solid fa-circle fa-stack-2x"></i>
                  <i
                    :class="[
                      'fa-brands',
                      item.icon,
                      'fa-stack-1x',
                      'text-black',
                    ]"
                  ></i>
                </span>
                <span>{{ item.name }}</span>
              </a>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text">
          <form id="contact-form" method="post" @submit.prevent="handleSubmit">
            <div class="form-group mb-3">
              <label for="name">Name:</label>
              <input
                v-model="formData.name"
                type="text"
                name="name"
                id="name"
                required
                class="
                form-control form-control-sm
                rounded-3
                bg-transparent
                text"
                aria-label="name"
              />
            </div>
            <div class="form-group mb-3">
              <label for="email">Email:</label>
              <input
                v-model="formData.email"
                type="email"
                required
                name="email"
                id="email"
                class="
                form-control form-control-sm
                bg-transparent
                rounded-3
                text"
                aria-label="email"
              />
            </div>
            <div class="form-group mb-3">
              <label for="message">Message:</label>
              <textarea
                required
                v-model="formData.message"
                rows="5"
                name="message"
                id="message"
                class="
                form-control form-control-sm
                rounded-3
                bg-transparent
                text"
                aria-label="message"
              ></textarea>
            </div>
            <div v-if="formStatus" v-html="formStatus" class="small mt-3"></div>
            <button
              type="submit"
              class="btn btn-outline-light rounded-pill primaryColorBorder rounded-3 px-3 btn-sm"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Contact",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      formData: {
        name: "",
        email: "",
        message: "",
      },
      formStatus: "",
      socialMediaHandles: [
        {
          name: "LinkedIn",
          icon: "fa fa-linkedin",
          link: "https://www.linkedin.com/in/olawale-oladiran",
        },
        {
          name: "Github",
          icon: "fa fa-github",
          link: "https://github.com/Gblack-Hub",
        },
        {
          name: "Upwork",
          icon: "fa fa-upwork",
          link: "https://www.upwork.com/freelancers/~0157c1cf6cfe095e67",
        },
      ],
    };
  },
  methods: {
    handleSubmit() {
      if (
        !this.formData.email ||
        !this.formData.name ||
        !this.formData.message
      ) {
        this.formStatus = `<div class='alert alert-warning'>
              Please fill all fields.
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>`;
        return;
      }
      // Reset form status
      this.formStatus =
        "<span><i class='fa fa-spinner fa-spin me-2'></i>Submitting...</span>";

      const form = document.querySelector("form");
      const formData = new FormData(form);

      formData.append("service_id", "service_c39i16r");
      formData.append("template_id", "template_3qnlde5");
      formData.append("user_id", "lDzkPycSSxjii9U-x");

      fetch("https://api.emailjs.com/api/v1.0/email/send-form", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            this.formStatus = `<div class='alert alert-success alert-dismissible fade show' role='alert'>
                Thank you for your message! We will get back to you soon.
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>`;
            this.$gtag.event("contact_submitted", {
              event_category: "Contact",
              event_label: "successful",
            });
            this.resetForm();
          } else {
            this.formStatus = `<div class='alert alert-danger alert-dismissible fade show' role='alert'>
                Something went wrong. Please try again later or reach out via LinkedIn
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>`;
            throw new Error("Form submission failed");
          }
        })
        .catch(() => {
          this.formStatus = `<div class='alert alert-danger'>
              Something went wrong. Please try again later or reach out via LinkedIn
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>`;
        });
    },

    resetForm() {
      // Reset form fields after successful submission
      this.formData.name = "";
      this.formData.email = "";
      this.formData.message = "";
    },

    goToSocial(social) {
      this.$gtag.event("socials_opened", {
        event_category: "Socials",
        event_label: social.name,
      });
    },
  },
};
</script>
<style scoped>
.text {
  color: rgba(255, 255, 255, 0.7);
}
.white-bg {
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
