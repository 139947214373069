<template>
  <section class="py-5 darkContainerBox" id="statsSection">
    <div class="container">
      <h2 class="sectionTitle text-white">About Me</h2>
      <hr class="topicLine" />
      <div class="mt-5">
        <p class="text">
          I am a full stack developer and have contributed to a wide range of
          dynamic projects across industries such as Fintech, Fashion & Apparel,
          Marketplace, and E-commerce. I specialize in modern technologies like
          HTML, CSS, SASS, JavaScript, TypeScript, React.js, Next.js, GraphQL,
          Tailwind, and Bootstrap, among others, to build engaging and
          high-performance software solutions.
        </p>

        <p class="text">
          I am also a certified educator and have mentored over 20 software
          developers. When I'm not doing programming, I like spending my time
          reading, watching movies or simply enjoying music. Feel free to
          connect with me on
          <a
            @click="goToSocial"
            href="https://www.linkedin.com/in/olawale-oladiran/"
            class="text-white"
            target="_blank"
            >LinkedIn</a
          >
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutMe",
  methods: {
    goToSocial() {
      this.$gtag.event("socials_opened", {
        event_category: "Socials",
        event_label: "LinkedIn",
      });
    },
  },
};
</script>

<style>
.text {
  color: rgba(255, 255, 255, 0.7);
}
</style>
