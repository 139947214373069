<template>
  <section class="container py-5 mt-5 text-white" id="projectSection">
    <div class="col-12">
      <h2 class="sectionTitle">Projects</h2>
      <hr class="topicLine" />
    </div>

    <div class="row justify-content-center mt-5">
      <div
        v-for="project in projectList"
        :key="project"
        class="
          col-sm-12 col-md-6 col-lg-4 col-xl-4
          d-flex
          align-content-stretch
          mb-3 mb-md-4
        "
      >
        <Project :project="project" />
      </div>
    </div>
  </section>
</template>

<script>
import Project from "./Project.vue";

export default {
  name: "Projects",
  components: {
    Project,
  },
  data() {
    return {
      projectList: [
        {
          id: "acd",
          name: "Atlantic Cloud Data",
          detail: "Data agency website",
          technologies: ["React.js", "Next.js", "Bootstrap", "Node.js"],
          type: "frontend",
          image: "acd",
          links: {
            source: "",
            doc: "",
            website: "https://www.atlanticclouddata.com/",
          },
        },
        {
          id: "pageone",
          name: "Page One",
          detail: "Business consultancy website",
          technologies: ["React.js", "Next.js", "Bootstrap", "GitLab"],
          type: "frontend",
          image: "mypageone",
          links: {
            source: "",
            doc: "",
            website: "https://www.mypageone.ng/",
          },
        },
        {
          id: "quikstays",
          name: "Quikstays",
          detail: "A per hour hotel booking system.",
          technologies: ["React.js", "Redux", "Material UI"],
          type: "frontend",
          image: "quikstays",
          links: {
            source: "",
            doc: "",
            website: "https://www.quikstays.com",
          },
        },
        {
          id: "saas-website",
          name: "SAAS Website",
          detail: "A Free SAAS website",
          technologies: ["Next.js", "React.js", "Tailwind", "Framer Motion"],
          type: "frontend",
          image: "saas-website",
          links: {
            source:
              "https://www.figma.com/design/JgeIAEvWSaEtqjzrNi9aC4/SaaS-Website-UI-Kit-%E2%80%94-Framer-Website-Kit-(Community)?node-id=0-1&node-type=canvas&t=cTOOQCtThqe1kKaa-0",
            doc: "",
            website: "https://saas-website-rose.vercel.app/",
          },
        },
        {
          id: "Kingsdelight",
          name: "Kingsdelight Glory School",
          detail: "School Website and School Portal",
          technologies: ["HTML", "CSS", "JavaScript", "PHP", "Bootstrap"],
          type: "fullstack",
          image: "Kingsdelight",
          links: {
            source: "",
            doc: "",
            website: "https://kingsdelightgloryschool.ng",
          },
        },
        // {
        //   name: "Loan Application (Frontend)",
        //   detail:
        //     "Pay only 30% of your checkout and spread remaining balance repayment for up to 6 months.",
        //   technologies: ["React.js", "Material UI", "GitHub"],
        //   image: "loan-app",
        //   type: "frontend",
        //   links: {
        //     source: "",
        //     doc: "",
        //     website: "https://loan-system.netlify.app",
        //   },
        // },
        // {
        //   name: "Loan App API",
        //   detail:
        //     "A full loan app application that enables a user request for loan and repay loans. Admin can approve, reject and disburse loans.",
        //   technologies: ["Node.js", "Express.js", "JavaScript", "Bootstrap"],
        //   type: "backend",
        //   links: {
        //     source: "",
        //     doc:
        //       "https://github.com/Gblack-Hub/loan-app-api/blob/main/README.md",
        //     website: "https://gblack-loan-api.herokuapp.com/",
        //   },
        // },
        {
          id: "portfolio",
          name: "My Portfolio",
          detail: "Know more about me.",
          technologies: ["Vue.js", "Node.js", "Bootstrap"],
          type: "fullstack",
          image: "portfolio",
          links: {
            source: "",
            doc: "",
            website: "https://gblack.dev",
          },
        },
        {
          id: "aiquizgenerator",
          name: "AI quiz generator",
          detail: "Generate quizzes from text with the power of AI",
          technologies: ["Next.js", "TailwindCSS", "OpenAI"],
          type: "fullstack",
          image: "aiquizgenerator",
          links: {
            source: "https://github.com/Gblack-Hub/quiz-generator.git",
            doc: "",
            website: "http://generate-quiz-ai.vercel.app",
          },
        },
        {
          id: "medillery",
          name: "Medillery",
          detail: "A freelancing platform for graphic designers.",
          technologies: ["React", "Redux", "Material UI", "CSS"],
          type: "frontend",
          image: "medillery",
          links: {
            source: "",
            doc: "",
            website: "https://www.medillery.com",
          },
        },
        // {
        //   name: "Dice",
        //   detail: "A virtual gaming platform.",
        //   technologies: ["Vanilla JS", "React", "Bootstrap5", "JQuery", "CSS"],
        //   type: "frontend",
        //   links: {
        //     source: "",
        //     doc: "",
        //     website: "https://dice.ng",
        //   },
        // },
        // {
        //   name: 'Student Attendance System',
        //   detail: 'Daily classroom attendance, time-table and more.',
        //   technologies: ['AngularJS', 'PHP', 'Bootstrap4'],
        //   type: 'fullstack',
        //   links: {
        //     source: '',
        //      doc: '',
        //     website: 'https://student-attendance-sys.herokuapp.com',
        //   },
        // },
        // {
        //   name: 'Online Banking System',
        //   detail: 'Simulate basic banking operations with this application.',
        //   technologies: ['JavaScript', 'PHP', 'Bootstrap4'],
        //   type: 'fullstack',
        //   links: {
        //     source: '',
        //     doc: '',
        //     website: 'http://sas.hstn.me',
        //   },
        // },
        {
          id: "cars",
          name: "Big Cars Info Website",
          detail: "View Cars, prices and related information.",
          technologies: ["Vuejs", "Vuetify", "SCSS"],
          type: "fullstack",
          image: "cars",
          links: {
            source: "https://github.com/Gblack-Hub/car-shop.git",
            doc: "",
            website: "https://vue-cars-shop.netlify.app",
          },
        },
        // {	name: 'React Store',
        // 	detail: 'A mini e-commerce store.',
        // 	technologies: ['Reactjs', 'PHP', 'Bootstrap4'],
        //	type: "fullstack",
        // 	links: {
        // 		source: '#',
        //    doc: '',
        // 		website: 'https://online-banking-sys.herokuapp.com/login.php'
        // 	}
        // }
      ],
    };
  },
};
</script>

<style></style>
