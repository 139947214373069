<template>
  <main>
    <transition name="fade" appear>
      <Hero />
    </transition>
    <AboutMe />
    <Projects />
    <Contact />
    <!-- <Testimonies /> -->
    <!-- <Stats /> -->
    <Footer />

    <button v-if="showButton" class="btn go-to-top" @click="scrollToTop">
      ↑
    </button>
  </main>
</template>

<script>
// @ is an alias to /src
import Projects from "@/components/homepage/projects/Projects.vue";
import Hero from "@/components/homepage/hero/Hero.vue";
import Contact from "@/components/homepage/contact/Contact.vue";
import Footer from "@/components/homepage/footer/Footer.vue";
import AboutMe from "../components/homepage/about-me/AboutMe.vue";
// import Testimonies from "@/components/homepage/testimonies/Testimonies.vue";
// import Stats from "../components/homepage/stats/Stats.vue";

export default {
  name: "Home",
  components: {
    Hero,
    Projects,
    Contact,
    Footer,
    AboutMe,
    // Testimonies,
    // Stats,
  },
  data() {
    return {
      showButton: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll() {
      this.showButton = window.scrollY > 100;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.go-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  color: white;
  background-color: var(--primary-color);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: none;
}

.go-to-top {
  display: block;
}

.go-to-top:hover {
  background-color: rgba(0, 197, 142, 0.5);
}

.fade-enter-from {
  opacity: 0;
  transform: translateY(20px);
}
.fade-enter-to {
  opacity: 1;
  transform: translateY(0);
}
.fade-enter-active {
  transition: all 1.5s ease;
}
</style>
