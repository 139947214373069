<template>
  <footer class="text-center bg-black">
    <div class="col-12 py-3 small text-white-50">
      <span class="primaryColor">&copy;</span> {{ currentYear }} Olawale
      Oladiran. All rights reserved.
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
