<template>
  <section
    class="
      heroBackgroundSection
      d-flex
      justify-content-center
      align-items-center
    "
  >
    <div class="container">
      <div class="row text-white align-items-center">
        <div class="col-sm-12 col-md-6 col-lg-7 col-xl-7 mb-5 mb-md-0">
          <h1 class="introText">
            <span class="text-white-50">Hi, I'm</span><br />Ola Oladiran
          </h1>
          <div class="text-white-50 mb-2">
            <Text
              text="I craft fast, sleek and responsive software solutions."
            />
          </div>
          <a
            @click="goToContactSection"
            class="
                btn btn-outline-light
                primaryColorBorder
                py-1
                rounded-pill
                text-capitalize
                mt-3
                button-animation
              "
            href="#contact-section"
          >
            <small class="me-2">Contact me</small>
            <span><i class="fa fa-long-arrow-right primaryColor"></i></span>
          </a>
          <!-- <a
            class="
              btn
              primaryBgColor
              py-1
              rounded-pill
              text-capitalize
              mx-2
              mt-3
              button-animation
            "
            target="_blank"
            href="https://www.upwork.com/freelancers/~0157c1cf6cfe095e67"
          >
            <small class="px-2 text-white">Hire me on Upwork</small>
          </a> -->
        </div>

        <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5">
          <div class="heroImageContainer">
            <img
              class="fitImage"
              alt="olawale"
              loading="lazy"
              src="../../../assets/images/hero1.webp"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Text from "../../Text.vue";

export default {
  name: "Hero",
  data() {
    return {};
  },
  components: {
    Text,
  },
  methods: {
    goToContactSection() {
      this.$gtag.event("contact_me_clicked", {
        event_category: "Contact",
      });
    },
  },
};
</script>

<style scoped>
/* Add dramatic button hover animation */
@keyframes dramaticHoverEffect {
  0% {
    transform: rotate(0deg);
    filter: brightness(1);
  }
  25% {
    transform: rotate(10deg);
    filter: brightness(1.2);
  }
  50% {
    transform: rotate(-10deg);
    filter: brightness(1.3);
  }
  75% {
    transform: rotate(5deg);
    filter: brightness(1.4);
  }
  100% {
    transform: rotate(0deg);
    filter: brightness(1);
  }
}

.button-animation {
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.button-animation:hover {
  animation: dramaticHoverEffect 0.8s ease-in-out forwards;
}

.button-animation i {
  transition: transform 0.4s ease-in-out;
}

.button-animation:hover i {
  transform: translateX(10px) rotate(30deg); /* Icon movement and rotation */
}

.introText {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3.1rem;
}
.heroBackgroundSection {
  padding-top: 70px;
  padding-bottom: 70px;
}
.heroImageContainer {
  border-radius: 20px;
}
@media screen and (min-width: 576px) {
  .heroBackgroundSection {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .introText {
    font-size: 4rem;
  }
}

@media screen and (min-width: 992px) {
  .heroImageContainer {
    height: 570px;
  }
}
</style>
